"use strict";

angular.module("informaApp")
    .service("CompanyProfileStore", function () {
        var data = [];
        var activeCompanyId = null;

        return {
            setActiveCompanyId: function (value) {
                activeCompanyId = value;
            },
            getActiveCompanyId: function () {
                return activeCompanyId;
            },
            saveCompanyProfile: function (profile) {
                this.removeProfile(profile.companyId);

                data.push(profile);

                return data.length - 1;
            },
            getCompanyProfileById: function (id) {
                var profile = _.find(data, function (x) {
                    return x.companyId == id;
                });

                return _.merge({}, profile);
            },
            getAllCompanyProfiles: function () {
                return _.merge([], data);
            },
            removeProfile: function (companyId) {
                var obj = _.find(data, function (x) {
                    return x.companyId == companyId;
                });

                if (obj){
                    data.splice(data.indexOf(obj), 1);
                }

                if (data.length === 0){
                    activeCompanyId = null;
                }
            },
            count: function () {
                return data.length;
            },
            getCompanyIdByHash: function (hash) {
                var obj = _.find(data, function (x) {
                    return x.hash == hash;
                });

                return obj == null ? null : obj.companyId;
            }
        }
    });